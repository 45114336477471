import { ReactElement } from 'react'
import { AdProps, TopMediathekTeaserCardProps } from '@sport1/types/web'
import { GetMediathekHeroTeaserComponentProps } from '../types'
import MediathekHeroTeaser from '@/components/MediathekHeroTeaserCard/MediathekTeaser'

interface SingleMediathekHeroTeaserProps extends GetMediathekHeroTeaserComponentProps {
    teaser: TopMediathekTeaserCardProps | AdProps
    backgroundColor?: 'pearl' | 'onyx' | 'TRANSPARENT'
}

const SingleMediathekHeroTeaser = ({
    teaser,
    teaserType,
    key,
    headlineNumberOfLines,
    noExpand,
    isActive,
    optionsLength,
    handleNext,
    handlePrevious,
}: SingleMediathekHeroTeaserProps): ReactElement | null => {
    if (!('content' in teaser)) return null

    return (
        <MediathekHeroTeaser
            key={`${teaser.componentKey}-${key}`}
            teaserType={teaserType}
            teaser={teaser}
            optionsLength={optionsLength}
            expand={!noExpand}
            activeOption={isActive}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            headlineNumberOfLines={headlineNumberOfLines}
        />
    )
}

export default SingleMediathekHeroTeaser
