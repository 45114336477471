import React, { FC, memo, useCallback } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ComponentType, AdProps, MediathekTeaserProps } from '@sport1/types/web'
import { useRouter } from 'next/router'
import { HeroSliderProps } from '../types'
import ProgressBar from '../ProgressBar'
import SingleMediathekHeroTeaser from '../SingleMediathekTeaser'
import useHero from '@/hooks/useHero'

const HeroSlider: FC<HeroSliderProps> = ({ teaser }) => {
    const router = useRouter()
    const { currentIndex, handleOptionClick, progress, handleNext, handlePrevious } = useHero(
        teaser.length
    )

    const handleTeaserClick = useCallback(
        (index: number) => () => handleOptionClick(index),
        [handleOptionClick]
    )

    const handleTeaserDoubleClick = useCallback(
        (teaserContent: AdProps | MediathekTeaserProps) => () => {
            if (teaserContent?.slug) {
                router.push(teaserContent.slug)
            }
        },
        [router]
    )

    return (
        <NonFlexingContainer testID="mediathek-hero-teaser-wrapper">
            <NonFlexingContainer testID="mediathek-hero-teaser">
                <SingleMediathekHeroTeaser
                    index={currentIndex}
                    key={`${teaser[0].title}-${teaser[0].id}-${teaser[0].componentKey}`}
                    teaser={teaser[currentIndex]}
                    teaserType={ComponentType.HERO_TEASER}
                    isActive={false}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    optionsLength={teaser.length}
                />
            </NonFlexingContainer>

            {teaser.length > 1 && (
                <div
                    className="gap-[12px] mtlg:gap-[24px] pt-[12px] mtlg:pt-[24px] flex"
                    data-testid="mediathek-hero-option-wrapper"
                >
                    {teaser.map((teaserEntry, index) => {
                        if ('content' in teaserEntry) {
                            const teaserContent = teaserEntry.content[0]
                            if ('permalink' in teaserContent || 'duration' in teaserContent) {
                                return (
                                    <NonFlexingContainer
                                        key={`mediathek-hero-option-${teaserEntry.title}-${index}`}
                                    >
                                        <ProgressBar
                                            progress={progress}
                                            isActive={index === currentIndex}
                                        />
                                        <div
                                            onClick={handleTeaserClick(index)}
                                            onDoubleClick={handleTeaserDoubleClick(teaserContent)}
                                        >
                                            <SingleMediathekHeroTeaser
                                                index={index}
                                                key={`${teaserEntry.title}-${teaserEntry.id}-${teaserEntry.componentKey}`}
                                                teaser={teaserEntry}
                                                teaserType={ComponentType.HERO_OPTION}
                                                isActive={index === currentIndex}
                                                optionsLength={teaser.length}
                                            />
                                        </div>
                                    </NonFlexingContainer>
                                )
                            }
                        }
                    })}
                </div>
            )}
        </NonFlexingContainer>
    )
}

export default memo(HeroSlider)
