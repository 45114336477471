import React, { FC, memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import useTheme from '@sport1/react-elements/useTheme'
import HeroSlider from './HeroSlider'
import { MediathekHeroTeaserCardProps } from './types'
import MediathekCard from '@/components/MediathekCard'
import { isCorrectSize } from '@/context/AdPlacementContext/AdPlacementProvider.utils'
import { BreakPointProps } from '@/helpers/breakpoint'
import useBreakpointDisplaySize from '@/hooks/useBreakpointDisplaySize'
import { useMobile } from '@/hooks/useMobile'
import { createPlaylistHref } from '@/utils/navigation/Navigator'

const MediathekHeroTeaserCard: FC<MediathekHeroTeaserCardProps> = ({
    teaser: unfilteredTeasers,
    title,
    tag,
    marginBottom,
    lazy,
    hintHref,
    teaserCardType,
}) => {
    const theme = useTheme()
    const displaySizes = useBreakpointDisplaySize(theme as BreakPointProps)
    const { isMobile } = useMobile()
    const { asPath } = useRouter()

    const titleHref =
        useMemo(() => {
            if (asPath.startsWith('/tv-video') || tag?.url?.includes('/layoutMode/VIDEO')) {
                return createPlaylistHref(tag)
            }
        }, [asPath, tag]) ?? hintHref

    const teaser = useMemo(
        () => unfilteredTeasers.filter(item => isCorrectSize(item, isMobile)),
        [isMobile, unfilteredTeasers]
    )

    if (teaser?.length === 0) return null

    return (
        <NonFlexingContainer
            margin="auto"
            overflow="hidden"
            backgroundColor="pearl"
            maxWidth={['369px', '604px', '762px', '1155px', '1548px']}
            width="100%"
        >
            <MediathekCard
                title={title}
                titleHref={titleHref}
                marginBottom={marginBottom}
                teaserCardType={teaserCardType}
            >
                <FlexingContainer testID="mediathek-card-content">
                    <HeroSlider {...{ title, lazy, teaser, displaySizes }} />
                </FlexingContainer>
            </MediathekCard>
        </NonFlexingContainer>
    )
}

export default memo(MediathekHeroTeaserCard)
