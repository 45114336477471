import React, { FC, memo } from 'react'

type ProgressBarProps = {
    progress: number
    isActive: boolean
}

const ProgressBar: FC<ProgressBarProps> = ({ progress, isActive }) => {
    return (
        <div
            data-testid="progress-bar-container"
            className="relative bg-[#FFFFFF80] h-[4px] w-full"
        >
            <div
                data-testid="progress-bar"
                className="absolute bg-[#106BFE] h-full"
                style={{
                    width: isActive ? `${progress}%` : '0%',
                }}
            ></div>
        </div>
    )
}

export default memo(ProgressBar)
