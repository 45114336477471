import { useState, useEffect, useCallback, useRef } from 'react'

const INTERVAL_TIME = 15000

type useHero = {
    currentIndex: number
    progress: number
    resetProgress: () => void
    handleNext: () => void
    handlePrevious: () => void
    handleOptionClick: (index: number) => void
}

const useHero = (optionsLength?: number): useHero => {
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [progress, setProgress] = useState<number>(0)
    const animationRef = useRef<number | null>(null)
    const startTimeRef = useRef<number | null>(null)
    const progressRef = useRef<number>(0)

    const clearAnimation = () => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current)
        }
    }

    const resetProgress = useCallback(() => {
        setProgress(0)
        progressRef.current = 0
        startTimeRef.current = null
    }, [])

    const handleNext = useCallback(() => {
        setCurrentIndex(prevIndex => {
            if (optionsLength && prevIndex >= optionsLength - 1) {
                return 0
            }
            return prevIndex + 1
        })
        resetProgress()
    }, [optionsLength, resetProgress])

    const handlePrevious = useCallback(() => {
        setCurrentIndex(prevIndex => {
            if (optionsLength && prevIndex === 0) {
                return optionsLength - 1
            }
            return prevIndex - 1
        })
        resetProgress()
    }, [optionsLength, resetProgress])

    const handleOptionClick = useCallback(
        (index: number) => {
            setCurrentIndex(index)
            resetProgress()
        },
        [resetProgress]
    )

    const animate = useCallback(
        (timestamp: number) => {
            if (!startTimeRef.current) startTimeRef.current = timestamp
            const elapsedTime = timestamp - startTimeRef.current

            const newProgress = Math.min((elapsedTime / INTERVAL_TIME) * 100, 100)
            setProgress(newProgress)

            progressRef.current = newProgress

            if (Math.abs(progressRef.current - progress) >= 1) {
                setProgress(newProgress)
            }

            if (elapsedTime < INTERVAL_TIME) {
                animationRef.current = requestAnimationFrame(animate)
            } else {
                setCurrentIndex(prevIndex => (prevIndex + 1) % (optionsLength || 1))
                resetProgress()
                animationRef.current = requestAnimationFrame(animate)
            }
        },
        [optionsLength, progress, resetProgress]
    )

    useEffect(() => {
        if (optionsLength && optionsLength > 1) {
            clearAnimation()
            animationRef.current = requestAnimationFrame(animate)
        }

        return () => clearAnimation()
    }, [animate, optionsLength])

    return { currentIndex, progress, resetProgress, handleOptionClick, handleNext, handlePrevious }
}

export default useHero
